<script>
import { Line } from 'vue-chartjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export default {
  name: 'LineChart',
  extends: Line,
  props: {
    data: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },

  mounted () {
    this.addPlugin(ChartDataLabels)
    this.renderChart(this.data, this.options)
  },

  computed: {
    chartData: function () {
      return this.data
    }
  },

  watch: {
    data: function () {
      // this._chart.destroy()
      this.renderChart(this.data, this.options)
    }
  }
}
</script>
