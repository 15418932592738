import axios from 'axios'
import StorageService from '@/services/StorageService'

const DashboardReportService = {
  promises: {},

  initialize (report) {
    let data = StorageService.get(this.getStorageKeyName(report))
    if (!data || !data.data) {
      data = {
        data: null,
        updated: null
      }
    }
    StorageService.set(this.getStorageKeyName(report), data, null)
  },

  getStorageKeyName (report) {
    return 'dashboardReportData_' + report
  },

  async get (report, filters, nocache) {
    const data = StorageService.get(this.getStorageKeyName(report))
    if (this.dataIsCurrent(data, filters) && !nocache) {
      return new Promise((resolve) => { resolve(data) })
    } else if (!this.promises || !this.promises[report]) {
      this.promises[report] = this.fetch(report, filters)
    }
    return this.promises[report]
  },

  fetch (report, filters) {
    return new Promise((resolve, reject) => {
      axios({
        url: axios.defaults.baseURL + 'report/get/',
        method: 'POST',
        data: JSON.stringify({ report: report, filters: filters })
      }).then((response) => {
        if (response.data && response.data.result) {
          const d = this.save(report, response.data.info, filters)
          resolve(d)
        } else {
          // api says we weren't successful
          reject(response.data)
        }
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        delete this.promises[report]
      })
    })
  },

  save (report, data, filters) {
    StorageService.set(this.getStorageKeyName(report), { data: data, filters: JSON.stringify(filters), updated: new Date() }, null)
    return StorageService.get(this.getStorageKeyName(report))
  },

  dataIsCurrent (data, filters) {
    return (data && data.data && JSON.stringify(filters) === data.filters)
  }
}

export default DashboardReportService
